<template>
    <div class="page">
        <div v-html="info.content"></div>
    </div>
</template>
<script>
import http from '@/http';

export default {
    data(){
        return {
            range: [],
            info:{}
        };
    },
    created() {
        http.post('/?cmd=com.xqxc.api.pigear.biz.platform.AppH5UrlService.appH5JumpUrl', {
            systemType: 'USER_APP',
            code:'PAY_PROTOCOL'
        }).then(res => {
            if(res.result.type === 'HTTP'){
                location.replace(res.result.content)
            }
            this.info = res.result
            document.title = res.result.title
        })
    },
    mounted() {
    },
    methods: {
    },
}
</script>
<style lang="less" scoped>
.page { 
    padding: .3rem;
    font-size: .28rem;
    line-height: 1.5;
    
}
    
</style>
    